$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​







    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 300; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggler
    // *
    // *
    $(".js-toggler-button").click(function(){
        $(this).toggleClass("active").parents(".js-toggler").toggleClass("business");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion-item").toggleClass("active").siblings().removeClass("active");
        $(this).parents(".js-accordion-item").find(".js-accordion-content").slideToggle();
        $(this).parents(".js-accordion-item").siblings().find(".js-accordion-content").slideUp();
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * loader
    // *
    // *
    if ($(".js-loader")) {
        $(".js-loader").delay(5500).fadeOut();
    }

    $(".js-loader").click(function(){
        $(".js-loader").addClass("hide");
    });


});




// * * * * * * * * * * * * * * * * * * * * * * * * *
// * Nav Active when Element is in Viewpoint
// *
// *
var $sections = $('[data-nav-section]'),
    $nav = $(".js-nav"),
    navHeight = $(".js-header").outerHeight(); //;

$(window).on("load scroll", function () {
    var cur_pos = $(this).scrollTop() + 60;

    $sections.each(function() {
        var top = $(this).offset().top - navHeight, bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
            $nav.find('li').removeClass('active');
            $sections.removeClass('active');
            $(this).addClass('active');
            var key = $(this).data("nav-section");
            $nav.find('a[href*="#' + key + '"]').parent('li').addClass('active');
        }
    });
});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * modal window
// *
// *
$(window).on('hashchange load', function (event) {
    var hash = window.location.hash.split('#')[1],
        $modal = $('[data-modal="' + hash + '"]');

    if ($modal.length) {
        event.preventDefault();
        $(".js-modal").fadeOut();
        $modal.fadeToggle();
    }
});

// close modal
$(document).ready(function(event) {
    $(".js-modal-close").click(function(){
        $(this).parents(".js-modal").fadeToggle();
        window.location.hash = '#a';
    });
});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * heroImage
// *
// *
var $heroImage = $(".js-heroimage");

$(window).on("load resize scroll",function(e){
    if($(window).scrollTop() >= $heroImage.outerHeight()) {
        $heroImage.css("visibility", "hidden");
    }else {
        $heroImage.css("visibility", "visible");
    }
});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * backgroundwrapper
// *
// *
var $background = $(".js-backgroundwrapper");

$(window).on("load resize scroll",function(e){
    var scroll = ($(window).scrollTop() * 0.3) * -1;
    $background.css("background-position-y", scroll + "px");
});
